import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginException } from './exceptions/login.exception';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { CustomMessageException } from './exceptions/customMessage.exception';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(GlobalError) {
    const notificationsService = this.injector.get(NotificationsService);
    const message = GlobalError.message ? GlobalError.message : GlobalError.toString();

    if (GlobalError instanceof HttpErrorResponse) {
      if (GlobalError.status === 401) {
        const authService = this.injector.get(AuthService);
        // const router = this.injector.get(Router);
        authService.logout();
        notificationsService.error('Jelentkezzen be újra!', 'Lejárt a munkamenet');
      } else if (GlobalError.status === 400) {
        GlobalError.error.errors.forEach(err => {
          if (err.defaultMessage === null || err.defaultMessage === undefined) {
            notificationsService.error(err.code);
          } else {
            notificationsService.error(err.defaultMessage);
          }
        });
      } else {
        notificationsService.error(GlobalError.error.message, GlobalError.status.toString() + ' - ' + GlobalError.statusText.toString());
      }
    } else if (GlobalError instanceof LoginException) {
      notificationsService.error('Sikertelen bejelentkezés', '');
    } else if (GlobalError.hasOwnProperty('LoginException')) {
      notificationsService.error('Sikertelen bejelentkezés', '');
    } else if (GlobalError.hasOwnProperty('CustomMessageException')) {
      notificationsService.error('Hiba', GlobalError.message);
    } else {
      console.log(GlobalError);
    }
  }

}
