import { Injectable, OnDestroy } from '@angular/core';
import { Player, PlayerListModel } from './playerList.model';
import { HttpService } from '../../core/http/http.service';
import { DataShareService, DataShareTypes } from '../../core/DataShare/DataShare.service';
import { concatMap, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { PlayerServer } from '../../shared/dao/in/PlayerServer';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class PlayerListService implements OnDestroy {
  dndEnable = false;
  public readonly playerChange: Observable<PlayerServer>;
  public readonly playerRetreiveFromServer: Observable<PlayerServer[]>;
  private _players: PlayerListModel;
  private _originalPlayers: PlayerServer[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private httpService: HttpService,
    public DataShare: DataShareService) {
    this._players = new PlayerListModel();

    this.playerRetreiveFromServer = DataShare.list.pipe(concatMap(value => {
      if (value.dataType === DataShareTypes.DataType.DATE) {
        return httpService.getOwnActivePlayers(DataShare.date.begin, DataShare.date.end);
      } else if (value.dataType === DataShareTypes.DataType.SINGLEDATE) {
        return httpService.getOwnActivePlayers(DataShare.singleDate, DataShare.singleDate);
      }
    }));
    this.subscriptions.push(this.playerRetreiveFromServer.subscribe((value: PlayerServer[]) => {
      this._players.clear();
      for (let i = 0; i < value.length; i++) {
        this._players.addplayer({
          id: value[i].id,
          name: value[i].fullName,
          number: value[i].playerNumber,
          post: value[i].position,
          pic: value[i].picture,
          matchingName: value[i].matchingName,
          mobile: value[i].mobile,
          email: value[i].email,
          birthDate: value[i].birthDate
        });
      }
      this._players.players.sort((a, b) => a.name > b.name ? 1 : -1);
      this._originalPlayers = value;
      const found = this._players.players.findIndex((val) => {
        return val.id === DataShare.id;
      });
      if (found === -1) {
        DataShare.nextId(DataShare.ListTypes.TEAM, null);
      }
    }));
    this._players.team = [{ id: null, name: 'Fradi', pic: 'assets/img/bitmap@3x.png' }];
    this.playerChange = this.DataShare.idChange.pipe(
      distinctUntilChanged(),
      map(value => {
        if (value.data.type === DataShareTypes.ListType.PLAYER) {
          return this._originalPlayers.find((item: PlayerServer) => {
            return item.id === value.data.id;
          });
        }
      }),
      filter(value => Boolean(value))
    );
  }

  get model(): PlayerListModel {
    return this._players;
  }

  get active(): Player {
    return this._players.players.find((item) => {
      return item.id === this.DataShare.id;
    });
  }

  get activePlayerServer(): PlayerServer {
    return this._originalPlayers.find((item) => {
      return item.id === this.DataShare.id;
    });
  }

  public IsPlayerActive(): boolean {
    return (this.DataShare.listType === DataShareTypes.ListType.PLAYER
      && !!this.activePlayerServer);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });
  }
}
