import { Component, ViewChild } from '@angular/core';

import { MatSidenav } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sgs-root',
  templateUrl: './app.component.html',
  styleUrls: ['../assets/css/material_rewrite.css']
})
export class AppComponent {

  public title = 'sgs-ui';
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private translate: TranslateService
  ) {
    this.translate.addLangs(['hu', 'en']);
    this.translate.setDefaultLang('hu');

    const browserLang: string = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/hu/) ? browserLang : 'hu');
  }

}
