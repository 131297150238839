import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DataShareService } from '../../../core/DataShare/DataShare.service';

@Component({
  selector: 'sgs-team-card',
  templateUrl: './teamCard.component.html'
})
export class TeamCardComponent implements OnInit {
  @Input() team: { id: number, name: string, pic: string };
  @Input() active: number;
  @Output() update: EventEmitter<number> = new EventEmitter();

  public post = '-1';


  constructor(public DataShare: DataShareService) {
  }

  ngOnInit() {
  }

  new_choose(post: string) {
  }

}
