import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { PostPipe } from '../filter.pipe';
import { DataShareService } from '../../../core/DataShare/DataShare.service';

@Component({
  selector: 'sgs-player-card',
  templateUrl: './playerCard.component.html',
})

export class PlayerCardComponent implements OnInit {
  @Input() player: { id: number, name: string, number: number, post: string, pic: string };
  @Input() active: number;
  @Output() update: EventEmitter<number> = new EventEmitter();

  private copyable = 'COPYABLE';

  new_choose(id: number) {
  }

  constructor(public DataShare: DataShareService) {
  }

  ngOnInit() {
  }
}
