import { NgModule, Optional, SkipSelf, InjectionToken, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot } from '@angular/router';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgLoggerModule } from '@nsalaun/ng-logger';

import { NotFoundModule } from './not-found';
import { environment } from 'src/environments/environment';
import { DragulaModule, DragulaService, DragulaDirective } from 'ng2-dragula';

///////////////////////////////////
// Declare tree-shakeable tokens //
///////////////////////////////////
export const API_ENDPOINT = new InjectionToken<string>('apiEndpoint', {
  providedIn: 'root',
  factory: () => environment.baseApiUrl
});

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/');
}

/**
 * Components
 */
const coreComponents: any[] = [
];

/**
 * Services
 */
const coreServices: any[] = [
  {
    provide: externalUrlProvider,
    useValue: (route: ActivatedRouteSnapshot) => {
      const externalUrl = route.paramMap.get('externalUrl');
      window.open(externalUrl, '_self');
    }
  }
];

/**
 * Directives
 */
const coreDirectives: any[] = [
];

/**
 * Pipes
 */
const corePipes: any[] = [
];

@NgModule({
  imports: [
    CommonModule,
    NotFoundModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgLoggerModule.forRoot(environment.logLevel),
    /*DragulaModule.forRoot()*/
  ],
  exports: [coreComponents, TranslateModule],
  declarations: [corePipes, coreDirectives, coreComponents],
  providers: [coreServices]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        coreServices
      ]
    };
  }

}
