import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginException } from '../error/exceptions/login.exception';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _Expiration: Date;
  private _save = true;
  private _UIAuth: { roles: string[]; username: string; email: string; loginTime: number };

  constructor(private cookieService: CookieService) {
    const cookie_exp = this.cookieService.get('expiration');
    const cookie_UIAuth = this.cookieService.get('UIAuth');
    if (cookie_exp) {
      this._Expiration = new Date(cookie_exp);
    } else {
      this._Expiration = new Date(new Date().getTime() - 1000000);
    }
    if (cookie_UIAuth) {
      this._UIAuth = JSON.parse(cookie_UIAuth);
    } else {
      this._UIAuth = undefined;
    }
  }

  set save(value: boolean) {
    this._save = value;
  }

  get save(): boolean {
    return this._save;
  }

  get UIAuth(): { roles: string[]; username: string; email: string; loginTime: number } {
    return this._UIAuth;
  }

  get userName(): string {
    return this._UIAuth.username;
  }

  public logout() {
    this._Expiration = new Date(new Date().getTime() - 1000000);
    this._UIAuth = undefined;
    this.cookieService.delete('expiration');
    this.cookieService.delete('UIAuth');
  }

  public login(UIAuth: { roles: string[]; username: string; email: string; loginTime: number }) {
    if (UIAuth !== null && UIAuth !== undefined) {
      this._Expiration = new Date(new Date().setTime(new Date(UIAuth.loginTime).getTime() + 3 * 60 * 60 * 1000));
      this._UIAuth = UIAuth;
      if (this._save) {
        this.cookieService.set('expiration', this._Expiration.toISOString());
        this.cookieService.set('UIAuth', JSON.stringify(this._UIAuth));
      } else {
        this.cookieService.delete('expiration');
        this.cookieService.delete('UIAuth');
      }
    } else {
      throw new LoginException(101);
    }
  }

  public tokenNotExpired(): boolean {
    return this._Expiration.getTime() >= new Date().getTime();
  }

  public isUIAuthSet(): boolean {
    return this._UIAuth !== undefined && this._UIAuth !== null;
  }

  public isAdmin(): boolean {
    return this.isAuthenticated() && this._UIAuth.roles.includes('ADMIN');
  }

  public isPlayer(): boolean {
    return this.isAuthenticated() && this._UIAuth.roles.includes('PLAYER');
  }

  public isTrainer(): boolean {
    return this.isAuthenticated() && this._UIAuth.roles.includes('TRAINER');
  }

  public isAuthenticated(): boolean {
    if (this.tokenNotExpired() && this.isUIAuthSet()) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }
}
