import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataShareTypes } from '../DataShare/DataShare.service';
import { map } from 'rxjs/operators';
import { MatchList } from '../../shared/dao/in/MatchList';
import { SavePlayer } from '../../shared/dao/out/SavePlayer';
import { SaveTeam } from '../../shared/dao/out/SaveTeam';
import { Team } from '../../shared/dao/in/team';
import { MatchPlayer } from '../../shared/dao/in/MatchPlayer';
import { ValueAndDate } from '../../shared/dao/in/ValueAndDate';
import { PrevNextMatchDate } from '../../shared/dao/in/PrevNextMatchDate';
import { Training } from '../../shared/dao/in/Training';
import { Sprints } from '../../shared/dao/in/Sprints';
import { HighSpeedAndSprint } from '../../shared/dao/in/HighSpeedAndSprint';
import { TotalDurationT85HRMax } from '../../shared/dao/in/TotalDurationT85HRMax';
import { AccelDecel } from '../../shared/dao/in/AccelDecel';
import { WellnessDiffs } from '../../shared/dao/in/WellnessDiffs';
import { WellnessDiffsPrev } from '../../shared/dao/in/WellnessDiffsPrev';
import { WellnessAVG } from '../../shared/dao/in/WellnessAVG';
import { WellnessWithTrend } from '../../shared/dao/in/WellnessWithTrend';
import { WellnessWithPlayerFullName } from '../../shared/dao/in/WellnessWithPlayerFullName';
import { SaveMatch } from '../../shared/dao/out/SaveMatch';
import { UpdatePlayer } from '../../shared/dao/out/UpdatePlayer';
import { UploadHistory } from '../../shared/dao/in/UploadHistory';
import { ChangePassword } from '../../shared/dao/out/ChangePassword';
import { User } from '../../shared/dao/in/User';
import { SaveMatchPlayer } from '../../shared/dao/out/SaveMatchPlayer';
import { PlayerServer } from '../../shared/dao/in/PlayerServer';
import { PlayerPersonalData } from '../../shared/dao/in/PlayerPersonalData';
import { PlayerAVGOfMatch } from '../../shared/dao/in/PlayerAVGOfMatch';
import { NordBoard } from '../../shared/dao/in/NordBoard';

@Injectable({ providedIn: 'root' })
export class HttpService {
  readonly domain = '/api';

  constructor(private http: HttpClient) {
  }

  getMatchTypeString(matchType: DataShareTypes.MatchType): string {
    let matchTypes: string;
    switch (matchType) {
      case 0:
        matchTypes = 'training';
        break;
      case 1:
        matchTypes = 'match';
        break;
      case 2:
        matchTypes = 'training_match';
        break;
      case 3:
        matchTypes = 'training_match, training, match';
        break;
      case 4:
        matchTypes = 'match, training_match';
        break;
      default:
        matchTypes = 'match';
        break;
    }
    return matchTypes;
  }

  ForgotPassword(email: string): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams().set('email', email);
    return this.http.get(this.domain + '/password/forgot-password', { headers: headers, params: params });
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.domain + '/user/find-all/');
  }

  getMatchPlayerByMatchidAndTeamId(matchId: number, teamId: number): Observable<any> {
    return this.http.get<MatchPlayer[]>(this.domain + '/match-player/find-by-matchid-and-teamid/' + matchId + '/' + teamId);
  }

  getMatchStatus(matchId: number): Observable<UploadHistory[]> {
    return this.http.get<UploadHistory[]>(this.domain + '/match/match-status/' + matchId);
  }

  ChangePassword(changePassword: ChangePassword): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(changePassword);
    return this.http.post(this.domain + '/password/change-password', body, { headers: headers, observe: 'response' });
  }

  SaveTeam(team: SaveTeam): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(team);
    return this.http.post(this.domain + '/team/save', body, { headers: headers, observe: 'response' });
  }

  SavePlayer(player: SavePlayer): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(player);
    return this.http.post(this.domain + '/player/save', body, { headers: headers, observe: 'response' });
  }

  UpdatePlayer(player: UpdatePlayer): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(player);
    return this.http.post(this.domain + '/player/update', body, { headers: headers, observe: 'response' });
  }

  uploadFile(trainingId: number, type: DataShareTypes.FileTypes, date: Date, file: File): Observable<any> {
    const headers = new HttpHeaders();
    const body = new FormData();
    body.append('file', file);
    body.append('fileType', DataShareTypes.FileTypes[type]);
    return this.http.post(this.domain + '/upload-file/' + trainingId, body, { headers: headers, observe: 'response' });
  }

  getMatches(fromDate: Date, toDate: Date, teamId: number): Observable<MatchList[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('teamId', teamId.toString());
    return this.http.get<MatchList[]>(this.domain + '/match/list-between-dates-and-team-id', { headers: headers, params: params });
  }

  addMatch(match: SaveMatch): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(match);
    return this.http.post(this.domain + '/match/save', body, { headers: headers, observe: 'response' });
  }

  getTeams(own: boolean): Observable<{ id: number; name: string; fullName: string; matchingName: string; mine: boolean }[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams().set('isMine', own ? 'true' : 'false');
    return this.http.get<Team[]>(this.domain + '/team/list-by-is-mine', { headers: headers, params: params });
  }

  getPlainDailyData(id: number, toDate: Date, fromDate: Date, dataSetType: string): Observable<ValueAndDate[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType);
    return this.http.get<ValueAndDate[]>(this.domain + '/training/find-values-between-dates/' + id, { headers: headers, params: params });
  }

  getPrevNextMatchDate(date: Date): Observable<PrevNextMatchDate> {
    const headers = new HttpHeaders();
    const params = new HttpParams().set(
      'referenceDate',
      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    );
    return this.http.get<PrevNextMatchDate>(this.domain + '/match/find-prev-next-match-date', { headers: headers, params: params });
  }

  getTrainingChartData(
    id: number,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-trainings/' + id, { headers: headers, params: params });
  }

  getPlayerTrainingChartData(
    id: number,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-matches/' + id, { headers: headers, params: params });
  }

  getTrainingChartTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/trainings/', { headers: headers, params: params });
  }

  getSprintZonesChartData(id: number, toDate: Date, fromDate: Date, grouping: DataShareTypes.GroupingType): Observable<Sprints[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Sprints[]>(this.domain + '/training/player-sprints/' + id, { headers: headers, params: params });
  }

  getSprintZonesChartTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<Sprints[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Sprints[]>(this.domain + '/training/sprints/', { headers: headers, params: params });
  }

  getTHSChartData(id: number, toDate: Date, fromDate: Date, grouping: DataShareTypes.GroupingType): Observable<HighSpeedAndSprint[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<HighSpeedAndSprint[]>(this.domain + '/training/player-high-speed-and-sprints/' + id, {
      headers: headers,
      params: params
    });
  }

  getTHSChartTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<HighSpeedAndSprint[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<HighSpeedAndSprint[]>(this.domain + '/training/high-speed-and-sprints/', { headers: headers, params: params });
  }

  getHR85ChartData(id: number, toDate: Date, fromDate: Date, grouping: DataShareTypes.GroupingType): Observable<TotalDurationT85HRMax[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<TotalDurationT85HRMax[]>(this.domain + '/training/player-total-duration-t85_hr_max/' + id, {
      headers: headers,
      params: params
    });
  }

  getHR85ChartTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<TotalDurationT85HRMax[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<TotalDurationT85HRMax[]>(this.domain + '/training/total-duration-t85_hr_max/', {
      headers: headers,
      params: params
    });
  }

  getTrainingChartAvg(
    id: number,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    matchType: DataShareTypes.MatchType
  ): Observable<{ average: number }> {
    let duration: string;
    switch (matchType) {
      case 0:
        duration = '00:00:00';
        break;
      case 3:
        duration = '00:00:00';
        break;
      default:
        duration = '01:25:00';
        break;
    }
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('matchTypes', this.getMatchTypeString(matchType))
      .set('duration', duration);
    return this.http.get<{ average: number }>(this.domain + '/training/average-of-player/' + id, { headers: headers, params: params });
  }

  getTrainingChartTeamAvg(
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    matchType: DataShareTypes.MatchType,
    post: DataShareTypes.PostType
  ): Observable<{ average: number }> {
    let duration: string;
    switch (matchType) {
      case 0:
        duration = '00:00:00';
        break;
      case 3:
        duration = '00:00:00';
        break;
      default:
        duration = '01:25:00';
        break;
    }
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('dataSetName', dataSetType)
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('matchTypes', this.getMatchTypeString(matchType))
      .set('post', post)
      .set('duration', duration);
    return this.http.get<{ average: number }>(this.domain + '/training/average/', { headers: headers, params: params });
  }

  getOwnActivePlayers(fromDate: Date, toDate: Date): Observable<PlayerServer[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('fromDate', new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('toDate', new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0]);
    return this.http.get<PlayerServer[]>(this.domain + '/player/find-valid-players', { headers: headers, params: params });
  }

  uploadPlayerImage(id, image): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const params = new HttpParams();
    const formData = new FormData();
    formData.append('file', image);

    return this.http.post(this.domain + '/player/upload-picture/' + id, formData, { headers: headers, params: params });
  }

  getMmPlayerData(id: number, toDate: Date, fromDate: Date, grouping: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-total-distance-per-duration/' + id, {
      headers: headers,
      params: params
    });
  }

  getMmTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/total-distance-per-duration/', {
      headers: headers,
      params: params
    });
  }

  /*getMmPlayerAvg(id: number, toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('matchType', DataShareTypes.MatchType[matchType])
      .set('duration', '00:36:00');
    return this.http.get<{ average: number }>(this.domain + '/training/total-dist-per-duration-average-of-player/' + id,
      { headers: headers, params: params });
  }
  getMmTeamAvg(toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType, post: DataShareTypes.PostType)
  : Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('matchType', DataShareTypes.MatchType[matchType])
      .set('post', post)
      .set('duration', '00:36:00');
    return this.http.get<{ average: number }>(this.domain + '/training/total-dist-per-duration-average/',
      { headers: headers, params: params });
  }*/

  getDurationAverage(toDate: Date, fromDate: Date): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    return this.http.get<{ average: number }>(this.domain + '/training/duration-average/', { headers: headers, params: params });
  }

  getAccPerDeccData(id: number, toDate: Date, fromDate: Date, grouping: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-accel-per-decel/' + id, {
      headers: headers,
      params: params
    });
  }

  getAccPerDeccTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/accel-per-decel/', { headers: headers, params: params });
  }

  /*getAccPerDeccAvg(id: number, toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('matchType', DataShareTypes.MatchType[matchType])
      .set('duration', '00:36:00');
    return this.http.get<{ average: number }>(this.domain + '/training/accel-per-decel-average-of-player/' + id, {
      headers: headers,
      params: params
    });
  }

  getAccPerDeccTeamAvg(post: DataShareTypes.PostType, toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType)
  : Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - (toDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      .set('matchType', DataShareTypes.MatchType[matchType])
      .set('duration', '00:36:00')
      .set('post', post);
    return this.http.get<{ average: number }>(this.domain + '/training/accel-per-decel-average/', { headers: headers, params: params });
  }*/

  getAccDeccData(id: number, toDate: Date, fromDate: Date, grouping?: DataShareTypes.GroupingType): Observable<AccelDecel[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<AccelDecel[]>(this.domain + '/training/player-accelerate-decelerate/' + id, {
      headers: headers,
      params: params
    });
  }

  getAccDeccTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<AccelDecel[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<AccelDecel[]>(this.domain + '/training/accelerate-decelerate/', { headers: headers, params: params });
  }

  getPlayerMaxVelocity(id: number, toDate: Date, fromDate: Date, grouping?: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-max-velocity/' + id, { headers: headers, params: params });
  }

  getTeamMaxVelocity(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping?: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/max-velocity/', { headers: headers, params: params });
  }

  getMaxVelocity(id: number): Observable<{ maxVelocity: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams();
    return this.http.get<{ maxVelocity: number }>(this.domain + '/training/get-max-velocity/' + id, { headers: headers, params: params });
  }

  getPlayerMax(id: number, dataSetType: string, matchType: DataShareTypes.MatchType): Observable<{ max: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('fromDate', new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0])
      .set('toDate', new Date(Date.now()).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('matchTypes', this.getMatchTypeString(matchType));
    return this.http.get<{ max: number }>(this.domain + '/training/max-of-player/' + id, { headers: headers, params: params });
  }

  getTeamMax(dataSetType: string, post: DataShareTypes.PostType, matchType: DataShareTypes.MatchType): Observable<{ max: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('fromDate', new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0])
      .set('toDate', new Date(Date.now()).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('post', post)
      .set('matchTypes', this.getMatchTypeString(matchType));
    return this.http.get<{ max: number }>(this.domain + '/training/max/', { headers: headers, params: params });
  }

  register(username: string, password: string, email: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = { username: username, password: password, email: email };
    return this.http.post(this.domain + '/user/registration', JSON.stringify(body), { headers: headers });
  }

  login(
    username: string,
    password: string
  ): Observable<any> {
    const headers = new HttpHeaders();
    const body = '{"password":"' + password + '","email":"' + username + '"}';
    return this.http.post(this.domain + '/login', body, { headers: headers, observe: 'response' });
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams();
    return this.http.get(this.domain + '/logout', { headers: headers, params: params });
  }

  getWellnessTrend(fromDate: Date, toDate: Date, id: number): Observable<WellnessWithTrend[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);

    /*return of<WellnessWithTrend[]>([new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(),
    new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand()])
      .pipe(delay(1000));*/
    return this.http.get<WellnessWithTrend[]>(this.domain + '/wellness/find-between-days-of-player/' + id, {
      headers: headers,
      params: params
    });
  }

  getWellnessTeamTrend(fromDate: Date, toDate: Date, post: DataShareTypes.PostType): Observable<WellnessWithTrend[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);

    /*return of<WellnessWithTrend[]>([new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(),
    new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand(), new WellnessWithTrend().Rand()])
      .pipe(delay(1000));*/
    return this.http.get<WellnessWithTrend[]>(this.domain + '/wellness/find-between-days/', { headers: headers, params: params });
  }

  getWellnessAvg(id: number, toDate: Date, fromDate: Date): Observable<WellnessAVG> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    /*return of<WellnessAVG>(new WellnessAVG().Rand()).pipe(delay(1000));*/
    return this.http.get<WellnessAVG>(this.domain + '/wellness/find-avg-of-player/' + id, {
      headers: headers,
      params: params
    });
  }

  getWellnessTeamAvg(post: DataShareTypes.PostType, toDate: Date, fromDate: Date): Observable<WellnessAVG> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    // return of<WellnessAVG>(new WellnessAVG().Rand()).pipe(delay(1000));
    return this.http.get<WellnessAVG>(this.domain + '/wellness/find-avg/', { headers: headers, params: params });
  }

  getWellnessData(date: Date, id: number): Observable<WellnessDiffs> {
    const headers = new HttpHeaders();
    const params = new HttpParams().set(
      'trainingDate',
      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    );

    const ret = new WellnessDiffs();
    ret.Rand();
    // return of<WellnessDiffs>(ret).pipe(delay(1000));
    return this.http.get<WellnessDiffs>(this.domain + '/wellness/diff-on-day-of-player/' + id, { headers: headers, params: params });
  }

  getWellnessTeamData(date: Date, post: DataShareTypes.PostType): Observable<WellnessDiffsPrev> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('trainingDate', new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    const ret = new WellnessDiffsPrev();
    ret.Rand();
    // return of<WellnessDiffsPrev>(ret).pipe(delay(1000));
    return this.http.get<WellnessDiffsPrev>(this.domain + '/wellness/diff-on-day/', { headers: headers, params: params });
  }

  getWellnessFullData(date: Date, post: DataShareTypes.PostType): Observable<WellnessWithPlayerFullName[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('trainingDate', new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]);

    // return of<WellnessWithPlayerFullName[]>([ new WellnessWithPlayerFullName().Rand(),
    //  new WellnessWithPlayerFullName().Rand()]).pipe(delay(1000));
    return this.http.get<WellnessWithPlayerFullName[]>(this.domain + '/wellness/find-on-day-by-post/', {
      headers: headers,
      params: params
    });
  }

  getBorg(id: number, toDate: Date, fromDate: Date, dataSetType: string, grouping: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/rpe/find-borg-between-days-of-player/' + id, { headers: headers, params: params });
  }

  getTeamBorg(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/rpe/find-borg-between-days/', { headers: headers, params: params });
  }

  getBorgAvg(id: number, toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('matchType', this.getMatchTypeString(matchType))
      .set('duration', '01:25:00');
    return this.http.get<{ average: number }>(this.domain + '/rpe/borg-average-of-player/' + id, {
      headers: headers,
      params: params
    });
  }

  getBorgTeamAvg(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    matchType: DataShareTypes.MatchType
  ): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('matchType', this.getMatchTypeString(matchType));
    return this.http.get<{ average: number }>(this.domain + '/rpe/borg-average/', {
      headers: headers,
      params: params
    });
  }

  getRPE(id: number, toDate: Date, fromDate: Date, dataSetType: string, grouping: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/rpe/find-between-days-of-player/' + id, { headers: headers, params: params });
  }

  getTeamRPE(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    dataSetType: string,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('dataSetName', dataSetType)
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/rpe/find-between-days/', { headers: headers, params: params });
  }

  getRPEAvg(id: number, toDate: Date, fromDate: Date, matchType: DataShareTypes.MatchType): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('matchType', this.getMatchTypeString(matchType))
      .set('duration', '01:25:00');
    return this.http.get<{ average: number }>(this.domain + '/rpe/average-of-player/' + id, {
      headers: headers,
      params: params
    });
  }

  getPRETeamAvg(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    matchType: DataShareTypes.MatchType
  ): Observable<{ average: number }> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('post', post)
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('matchType', this.getMatchTypeString(matchType));
    return this.http.get<{ average: number }>(this.domain + '/rpe/average/', {
      headers: headers,
      params: params
    });
  }

  getRPEsOfMatch(
    fromDate,
    toDate
  ): Observable<{
    id: number;
    team1Name: string;
    team2Name: string;
    matchDate: string;
    matchTime: string;
    rpeOfPlayerList: Array<{ playerId: number; playerFullName: string; rpe: number; emailSendTime: string; isFullTimeParticipant: any }>;
  }[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    return this.http.get<any>(this.domain + '/rpe/list-rpes-of-match/', { headers: headers, params: params });
  }

  updateUser(user: User) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(user);
    return this.http.post(this.domain + '/user/update', body, { headers: headers, observe: 'response' });
  }

  addMatchPlayer(matchPalyer: SaveMatchPlayer): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const body = JSON.stringify(matchPalyer);
    return this.http.post(this.domain + '/match-player/save', body, { headers: headers, observe: 'response' });
  }

  getMatchPlayers(matchId: number): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams();
    return this.http.get(this.domain + '/match-player/find-by-matchid/' + matchId, { headers: headers, params: params });
  }

  sendRpe(matchId: number): Observable<any> {
    return this.http.get(this.domain + '/rpe/send-email/' + matchId);
  }

  forgotPasswordNew(changePass: ChangePassword, token: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = new HttpParams().set('token', token);
    const body = JSON.stringify(changePass);
    return this.http.post(this.domain + '/password/change-forgot-password', body, {
      headers: headers,
      params: params,
      observe: 'response'
    });
  }

  getVolumeTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/volume/', { headers: headers, params: params });
  }

  getVolumeData(id: number, toDate: Date, fromDate: Date, grouping?: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-volume/' + id, {
      headers: headers,
      params: params
    });
  }

  getIntensityTeamData(
    post: DataShareTypes.PostType,
    toDate: Date,
    fromDate: Date,
    grouping: DataShareTypes.GroupingType
  ): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('post', post)
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/intensity/', { headers: headers, params: params });
  }

  getIntensityData(id: number, toDate: Date, fromDate: Date, grouping?: DataShareTypes.GroupingType): Observable<Training[]> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('grouping', DataShareTypes.GroupingType[grouping]);
    return this.http.get<Training[]>(this.domain + '/training/player-intensity/' + id, {
      headers: headers,
      params: params
    });
  }

  exportCatapultRPEWellness(fromDate: Date, toDate: Date): Observable<any> {
    const headers = new HttpHeaders();
    const params = new HttpParams()
      .set('toDate', new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString().split('T')[0])
      .set('fromDate', new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]);
    return this.http
      .get<any>(`${this.domain}/training/catapult-rpe-wellness-export/`, {
        headers: headers,
        params: params,
        responseType: 'blob' as 'json'
      })
      .pipe(map((res: any) => new Blob([<any>res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })));
  }

  public removeCatapultByEventId(id: number): Observable<HttpResponse<void>> {
    return this.http.delete<HttpResponse<void>>(`${this.domain}/catapult/${id}`);
  }

  public getPlayerPersonalData(id: number): Observable<PlayerPersonalData[]> {
    return this.http.get<PlayerPersonalData[]>(`${this.domain}/player-page/player-personal-data/${id}`);
  }

  public getPlayerMatchData(id: number): Observable<PlayerAVGOfMatch> {
    return this.http.get<PlayerAVGOfMatch>(`${this.domain}/player-page/player-avg-of-match/${id}`);
  }

  public getPlayerNoardBoardData(id: number): Observable<NordBoard[]> {
    return this.http.get<NordBoard[]>(`${this.domain}/player-page/nord-board/${id}`);
  }

  deleteMatch(matchId: number) {
    return this.http.delete(this.domain + '/match/delete/' + matchId);
  }

  deleteUploadedMatch(matchId: number) {
    return this.http.delete(this.domain + '/match/delete-uploaded-match/' + matchId);
  }
}
