import { Pipe, PipeTransform } from '@angular/core';
import {backendDateStringToDate} from '../../util/date/date-utils';


@Pipe({ name: 'toShortDateString' })
export class ToShortDateString implements PipeTransform {
  transform(value: Date, lang: string): string {
    switch (lang) {
      case 'HU': {
        return value.toLocaleDateString('hu-HU');
      }
      case 'EN': {
        return value.toLocaleDateString('en-US');
      }
    }
  }
}

@Pipe({ name: 'diffToNowYears' })
export class DiffToNowYears implements PipeTransform {
  transform(value: Date): number {
    const ageDifMs = Date.now() - value.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}

@Pipe({ name: 'diffBetweenYears' })
export class DiffBetweenYears implements PipeTransform {
  transform(value: Date, date_later: Date): number {
    const ageDifMs = date_later.getTime() - value.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}

@Pipe({ name: 'firstDayOfWeek' })
export class FirstDayOfWeek implements PipeTransform {
  transform(value: Date): Date {
    const day = value.getDay();
    const diff = value.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(new Date(value.setDate(diff)).setHours(0, 1, 0, 0));
  }
}

@Pipe({ name: 'lastDayOfWeek' })
export class LastDayOfWeek implements PipeTransform {
  transform(value: Date): Date {
    const date = (new FirstDayOfWeek).transform(value);
    return new Date(new Date(date.setDate(date.getDate() + 6)).setHours(23, 59, 0, 0));
  }
}

@Pipe({ name: 'diffBetweenDays' })
export class DiffBetweenDays implements PipeTransform {
  transform(value: Date, date_later: Date): number {
    return Math.floor(Math.abs(value.getTime() - date_later.getTime()) / 86400000);
    /*date_later.getDate() - value.getDate() + 1;*/
    /*ez csak akkor működik, ha egy hónapon belül van mindkét nap.*/
  }
}

@Pipe({ name: 'compareDateDays' })
export class CompareDateDays implements PipeTransform {
  transform(date_earlier: Date, date_later: Date): number {
    let val = (date_later.getTime() - date_earlier.getTime()) / 86400000;
    val = val > 0 ? Math.floor(val) : Math.ceil(val);
    switch (true) {
      case val > 0: {
        return 1;
      }
      case val < 0: {
        return -1;
      }
      case val === 0: {
        return 0;
      }
      default: {
        return -1;
      }
    }
  }
}

@Pipe({ name: 'lastDayOfMonth' })
export class LastDayOfMonth implements PipeTransform {
  transform(value: Date): Date {
    return new Date(new Date(value.setMonth(value.getMonth() + 1)).setDate(0));
  }
}

@Pipe({ name: 'firstDayOfMonth' })
export class FirstDayOfMonth implements PipeTransform {
  transform(value: Date): Date {
    return new Date(value.setDate(1));
  }
}

@Pipe({ name: 'isCurrentWeekIn' })
export class IsCurrentWeekIn implements PipeTransform {
  transform(value: Date, date_later: Date): boolean {
    return new LastDayOfWeek().transform(new Date()).getTime() <= date_later.getTime() &&
      new FirstDayOfWeek().transform(new Date()).getTime() >= value.getTime();
  }
}

@Pipe({ name: 'stringToDate' })
export class StringToDate implements PipeTransform {
  transform(value: string): Date {
    return new Date(value);
  }
}

@Pipe({name: 'backendStringToDate'})
export class BackendStringToDate implements PipeTransform {
  transform(value: string): Date {
    return backendDateStringToDate(value);
  }
}
