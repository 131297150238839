import { NgModule } from '@angular/core';
import {
  MatGridListModule,
  MatButtonModule,
  MatTabsModule,
  MatToolbarModule,
  MatCardModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatIconModule,
  MatTooltipModule,
  MatIconRegistry,
  MatMenuModule,
  MatRadioModule,
  MatSidenavModule,
  MatListModule,
  MatButtonToggleModule,
  MatSlideToggleModule,
  MatExpansionModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatBottomSheetModule,
  MatDatepickerModule,
  MatChipsModule,
  MatProgressSpinnerModule
} from '@angular/material';

@NgModule({
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatTabsModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatChipsModule
  ],
  exports: [
    MatGridListModule,
    MatButtonModule,
    MatTabsModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    MatDatepickerModule,
    MatChipsModule,
    MatProgressSpinnerModule
  ],
  providers: [
    MatIconRegistry
  ]
})

export class MaterialModule { }
