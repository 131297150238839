import { Injectable } from '@angular/core';
import { merge, BehaviorSubject, Observable } from 'rxjs';
import { CompareDateDays, DiffBetweenDays, FirstDayOfMonth, FirstDayOfWeek, LastDayOfMonth, LastDayOfWeek } from '../date/date.pipe';

export namespace DataShareTypes {
  export enum DataType {
    INIT,
    DATE,
    AVG,
    TREND,
    COMPARISON,
    ID,
    GROUPING,
    LOADING,
    LIST_POST,
    MATCH,
    MATCH_RANGE,
    DATA1_3,
    DATA1_7,
    SINGLEDATE,
    MATCHMAX,
    MATCHAVG,
    ACCR,
    MATCH_DATASET,
    HALFTIME,
    AVG_SUM
  }

  export enum FileTypes {
    CATAPULT,
    Instat
  }

  export enum GroupingType {
    DAILY,
    WEEKLY_SUM,
    MONTHLY_SUM,
    WEEKLY_AVG,
    MONTHLY_AVG
  }

  export enum MatchType {
    training,
    match,
    training_match,
    all,
    all_match
  }
  export enum PostType {
    TEAM = 'TEAM',
    Wing = 'Wing',
    Central_Defender = 'Central Defender',
    Full_Back = 'Full Back',
    Attacker = 'Attacker',
    Midfielder = 'Midfielder',
    Goalkeeper = 'Goalkeeper'
  }

  export enum ListType {
    MATCH,
    PLAYER,
    TEAM
  }
  export enum DatasetType {
    odometer,
    player_load,
    high_speed_total_distance,
    sprints,
    high_speed_and_sprints,
    max_velocity,
    accel_per_decel,
    ima,
    explosive_total_distance,
    total_dist_per_duration
  }

  export enum HalfTimeType {
    full,
    first,
    second
  }

}


@Injectable()
export class DataShareService {
  public isThereEstimation = true;
  public avgSetState = false;

  public readonly DataTypes = DataShareTypes.DataType;
  public readonly GroupingTypes = DataShareTypes.GroupingType;
  public readonly ListTypes = DataShareTypes.ListType;
  public readonly PostTypes = DataShareTypes.PostType;
  public readonly MatchTypes = DataShareTypes.MatchType;

  private readonly _date: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: { begin: Date, end: Date } }>;
  private readonly _avg: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _trend: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _comparison: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _id: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: { type: DataShareTypes.ListType, id: number } }>;
  private readonly _grouping: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: DataShareTypes.GroupingType }>;
  private readonly _loading: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _match: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: DataShareTypes.MatchType }>;
  private readonly _match_range: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: { begin: Date, end: Date } }>;
  private readonly _list_postType: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: DataShareTypes.PostType }>;
  private readonly _data1_3: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _data1_7: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _singleDate: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: Date }>;
  private readonly _matchAvg: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _ACCR: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;
  private readonly _matchMax: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;

  private readonly _matchDataset: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: DataShareTypes.DatasetType }>;
  private readonly _halfTime: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: DataShareTypes.HalfTimeType }>;
  private readonly _avg_sum: BehaviorSubject<{ dataType: DataShareTypes.DataType, data: boolean }>;

  public readonly list: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly chartTraining: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly loadDifferene: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly personalComponent: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly wellnessTable: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly wellnessCustom: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly wellnessTrend: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly dateSelect: Observable<{ dataType: DataShareTypes.DataType, data: any }>;
  public readonly matchChart: Observable<{ dataType: DataShareTypes.DataType, data: any }>;

  constructor() {

    this._date = new BehaviorSubject({
      dataType: DataShareTypes.DataType.DATE,
      data: { begin: new FirstDayOfWeek().transform(new Date()), end: new LastDayOfWeek().transform(new Date()) }
    });
    this._avg = new BehaviorSubject({ dataType: DataShareTypes.DataType.AVG, data: false });
    this._trend = new BehaviorSubject({ dataType: DataShareTypes.DataType.TREND, data: false });
    this._comparison = new BehaviorSubject({ dataType: DataShareTypes.DataType.COMPARISON, data: true });
    this._id = new BehaviorSubject({ dataType: DataShareTypes.DataType.ID, data: { type: DataShareTypes.ListType.TEAM, id: null } });
    this._grouping = new BehaviorSubject({ dataType: DataShareTypes.DataType.GROUPING, data: DataShareTypes.GroupingType.DAILY });
    this._loading = new BehaviorSubject({ dataType: DataShareTypes.DataType.LOADING, data: true });
    this._list_postType = new BehaviorSubject({ dataType: DataShareTypes.DataType.LIST_POST, data: DataShareTypes.PostType.TEAM });
    this._match = new BehaviorSubject({ dataType: DataShareTypes.DataType.MATCH, data: DataShareTypes.MatchType.match });
    this._match_range = new BehaviorSubject({
      dataType: DataShareTypes.DataType.MATCH_RANGE,
      data: { begin: new Date(new Date().setFullYear(new Date().getFullYear() - 1)), end: new Date() }
    });
    this._data1_3 = new BehaviorSubject({ dataType: DataShareTypes.DataType.DATA1_3, data: true });
    this._data1_7 = new BehaviorSubject({ dataType: DataShareTypes.DataType.DATA1_7, data: true });
    this._singleDate = new BehaviorSubject({ dataType: DataShareTypes.DataType.SINGLEDATE, data: new Date(Date.now()) });
    this._ACCR = new BehaviorSubject({ dataType: DataShareTypes.DataType.ACCR, data: true });
    this._matchAvg = new BehaviorSubject({ dataType: DataShareTypes.DataType.MATCHAVG, data: true });
    this._matchMax = new BehaviorSubject({ dataType: DataShareTypes.DataType.MATCHMAX, data: true });

    this._matchDataset = new BehaviorSubject({ dataType: DataShareTypes.DataType.MATCH_DATASET,
      data: DataShareTypes.DatasetType.odometer });
    this._halfTime = new BehaviorSubject({ dataType: DataShareTypes.DataType.HALFTIME, data: DataShareTypes.HalfTimeType.full });
    this._avg_sum = new BehaviorSubject({ dataType: DataShareTypes.DataType.AVG_SUM, data: false });

    this.chartTraining = new BehaviorSubject<{ dataType: DataShareTypes.DataType, data: any }>({
      dataType: DataShareTypes.DataType.ID,
      data: 0
    });

    this.loadDifferene = merge(this._date, this._id);
    this.personalComponent = this._id;
    this.list = merge(this._date, this._singleDate);
    this.chartTraining = merge(this._date, this._avg, this._trend, this._comparison, this._id, this._grouping,
      this._list_postType, this._match, this._match_range, this._data1_3, this._data1_7, this._matchAvg, this._matchMax, this._ACCR);
    this.wellnessTable = merge(this._singleDate, this._id, this._list_postType);
    this.wellnessCustom = merge(this._singleDate, this._list_postType);
    this.wellnessTrend = merge(this._date, this._id, this._trend, this._list_postType, this._match, this._match_range, this._avg);
    this.matchChart = merge(this._matchDataset, this._halfTime, this._avg_sum,
      this._id, this._avg, this._list_postType, this._match, this._match_range);
  }

  public get idChange(): Observable<{ dataType: DataShareTypes.DataType, data: { type: DataShareTypes.ListType, id: number } }> {
    return this._id.asObservable();
  }

  get matchMax(): boolean {
    return this._matchMax.getValue().data;
  }

  set matchMax(value: boolean) {
    if (this.matchMax !== value) {
      this._matchMax.next({
        dataType: DataShareTypes.DataType.MATCHMAX,
        data: value
      });
    }
  }

  get matchAVG(): boolean {
    return this._matchAvg.getValue().data;
  }

  set matchAVG(value: boolean) {
    if (this.matchAVG !== value) {
      this._matchAvg.next({
        dataType: DataShareTypes.DataType.MATCHAVG,
        data: value
      });
    }
  }

  get ACCR(): boolean {
    return this._ACCR.getValue().data;
  }

  set ACCR(value: boolean) {
    if (this.ACCR !== value) {
      this._ACCR.next({
        dataType: DataShareTypes.DataType.ACCR,
        data: value
      });
    }
  }

  get singleDate(): Date {
    return this._singleDate.getValue().data;
  }

  set singleDate(value: Date) {
    if (this.singleDate !== value) {
      this._singleDate.next({
        dataType: DataShareTypes.DataType.SINGLEDATE,
        data: value
      });
    }
  }

  get data1_3(): boolean {
    return this._data1_3.getValue().data;
  }

  set data1_3(value: boolean) {
    this._data1_3.next({
      dataType: DataShareTypes.DataType.DATA1_3,
      data: value
    });
  }

  get data1_7(): boolean {
    return this._data1_7.getValue().data;
  }

  set data1_7(value: boolean) {
    this._data1_7.next({
      dataType: DataShareTypes.DataType.DATA1_7,
      data: value
    });
  }

  get match(): string {
    return this._match.getValue().data.toString();
  }

  set match(value: string) {
    if (this.match !== value) {
      this._match.next({
        dataType: DataShareTypes.DataType.MATCH,
        data: DataShareTypes.MatchType[DataShareTypes.MatchType[Number.parseInt(value)]]
      });
    }
  }

  get matchType(): DataShareTypes.MatchType {
    return this._match.getValue().data;
  }
  set matchType(value: DataShareTypes.MatchType) {
    if (this.matchType !== value) {
      this._match.next({
        dataType: DataShareTypes.DataType.MATCH,
        data: DataShareTypes[value]
      });
    }
  }

  get match_range(): { begin: Date, end: Date } {
    return this._match_range.getValue().data;
  }

  set match_range(value: { begin: Date, end: Date }) {
    if (value !== this.match_range) {
      this._match_range.next({
        dataType: DataShareTypes.DataType.MATCH_RANGE,
        data: value
      });
    }
  }

  get isActualWeek(): boolean {
    return ((new CompareDateDays()).transform(new Date(), this.date.end) >= 0 && this.listType === DataShareTypes.ListType.PLAYER);
  }

  public nextId(type: DataShareTypes.ListType, id: number) {
    if ((this.listType !== type) || (this.id !== id)) {
      this._id.next({ dataType: DataShareTypes.DataType.ID, data: { type: type, id: id } });
    }
  }

  get list_post(): string {
    return this._list_postType.getValue().data.toString();
  }

  get list_postType(): DataShareTypes.PostType {
    return this._list_postType.getValue().data;
  }

  set list_post(value: string) {
    if (this.list_post !== value) {
      this._list_postType.next({
        dataType: DataShareTypes.DataType.LIST_POST,
        data: DataShareTypes.PostType[Object.entries(DataShareTypes.PostType).find(item => item[1] === value)[0]]
      });
    }
  }

  get loading(): boolean {
    return this._loading.getValue().data;
  }

  set loading(value: boolean) {
    if (this.loading !== value) {
      this._loading.next({ dataType: DataShareTypes.DataType.LOADING, data: value });
    }
  }

  set date(value: { begin: Date, end: Date }) {
    if (this.date !== value) {
      switch (this._grouping.getValue().data) {
        case this.GroupingTypes.DAILY: {
          break;
        }
        case this.GroupingTypes.WEEKLY_SUM: {
          value.begin = new FirstDayOfWeek().transform(value.begin);
          value.end = new LastDayOfWeek().transform(value.end);
          break;
        }
        case this.GroupingTypes.WEEKLY_AVG: {
          value.begin = new FirstDayOfWeek().transform(value.begin);
          value.end = new LastDayOfWeek().transform(value.end);
          break;
        }
        case this.GroupingTypes.MONTHLY_SUM: {
          value.begin = new FirstDayOfMonth().transform(value.begin);
          value.end = new LastDayOfMonth().transform(value.end);
          /*Itt az lesz a para, hogy ha beállítunk eredendően egy dátumot mondjuk július 1 vasárnaptól augusztus 31 péntekig,
           akkor automatikusan jun. 25- szept.2 lesz a beállított intervallum,
          és ezt hónapra konvertálva már nem 2, hanem 5 hónap lesz az intervallum és
          a bontás is 5 hónapot mutat majd (jun.1-szept-30.) pedig ilyen esetben szerintem(!) nem ez a cél. */
          break;
        }
        case this.GroupingTypes.MONTHLY_AVG: {
          value.begin = new FirstDayOfMonth().transform(value.begin);
          value.end = new LastDayOfMonth().transform(value.end);
          /*Itt az lesz a para, hogy ha beállítunk eredendően egy dátumot mondjuk július 1 vasárnaptól augusztus 31 péntekig,
           akkor automatikusan jun. 25- szept.2 lesz a beállított intervallum,
          és ezt hónapra konvertálva már nem 2, hanem 5 hónap lesz az intervallum és
          a bontás is 5 hónapot mutat majd (jun.1-szept-30.) pedig ilyen esetben szerintem(!) nem ez a cél. */
          break;
        }
        default: {
          break;
        }
      }
      this._date.next({ dataType: DataShareTypes.DataType.DATE, data: value });
    }
  }

  get isDateRangeEnough(): { weekly: boolean, monthly: boolean } {
    const term = new DiffBetweenDays().transform(this.date.begin, this.date.end);
    return {weekly: term < 6 /* 27*/, monthly: term < 29 /* 55*/};
  }

  set avg(value: boolean) {
    if (this.avg !== value) {
      this._avg.next({ dataType: DataShareTypes.DataType.AVG, data: value });
    }
  }

  set trend(value: boolean) {
    if (this.trend !== value) {
      this._trend.next({ dataType: DataShareTypes.DataType.TREND, data: value });
    }
  }

  set comparison(value: boolean) {
    if (this.comparison !== value) {
      this._comparison.next({ dataType: DataShareTypes.DataType.COMPARISON, data: value });
    }
  }

  set grouping(value: string) {
    if (this.grouping !== this.GroupingTypes[this.GroupingTypes[Number.parseInt(value)]]) {
      this._grouping.next({
        dataType: DataShareTypes.DataType.GROUPING,
        data: DataShareTypes.GroupingType[DataShareTypes.GroupingType[Number.parseInt(value)]]
      });
    }
  }

  get listType(): DataShareTypes.ListType {
    return this._id.getValue().data.type;
  }

  get date(): { begin: Date; end: Date } {
    return this._date.getValue().data;
  }

  get avg(): boolean {
    return this._avg.getValue().data;
  }

  get trend(): boolean {
    return this._trend.getValue().data;
  }

  get comparison(): boolean {
    return this._comparison.getValue().data;
  }

  get id(): number {
    return this._id.getValue().data.id;
  }

  get grouping(): string {
    return this._grouping.getValue().data.toString();
  }

  get groupingType(): DataShareTypes.GroupingType {
    return this._grouping.getValue().data;
  }

  get matchDataset(): string {
    return DataShareTypes.DatasetType[this._matchDataset.getValue().data];
  }
  get halfTime(): string {
    return this._halfTime.getValue().data.toString();
  }
  get avgSum(): boolean {
    return this._avg_sum.getValue().data;
  }

  set matchDataset(value: string) {
    if (this.matchDataset !== value) {
      this._matchDataset.next({
        dataType: DataShareTypes.DataType.MATCH_DATASET,
        data: DataShareTypes.DatasetType[value]
      });
    }
  }
  set halfTime(value: string) {
    if (this.halfTime !== value) {
      this._halfTime.next({
        dataType: DataShareTypes.DataType.HALFTIME,
        data: DataShareTypes.DataType[DataShareTypes.DataType[Number.parseInt(value)]]
      });
    }
  }
  set avgSum(value: boolean) {
    if (this.avgSum !== value) {
      this._avg_sum.next({
        dataType: DataShareTypes.DataType.AVG_SUM,
        data: value
      });
    }
  }
}
