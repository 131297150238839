// other imports
import {
  DiffToNowYears,
  FirstDayOfWeek,
  LastDayOfWeek,
  DiffBetweenDays,
  CompareDateDays,
  IsCurrentWeekIn,
  FirstDayOfMonth,
  LastDayOfMonth,
  StringToDate,
  BackendStringToDate
} from './date.pipe';
import { NgModule } from '@angular/core';
import { ToShortDateString } from './date.pipe';
import { DiffBetweenYears } from './date.pipe';

@NgModule({
  imports: [],
  declarations: [
    DiffToNowYears,
    ToShortDateString,
    DiffBetweenYears,
    FirstDayOfWeek,
    LastDayOfWeek,
    DiffBetweenDays,
    CompareDateDays,
    LastDayOfMonth,
    FirstDayOfMonth,
    IsCurrentWeekIn,
    StringToDate,
    BackendStringToDate
  ],
  exports: [
    DiffToNowYears,
    ToShortDateString,
    DiffBetweenYears,
    FirstDayOfWeek,
    LastDayOfWeek,
    DiffBetweenDays,
    CompareDateDays,
    LastDayOfMonth,
    FirstDayOfMonth,
    IsCurrentWeekIn,
    StringToDate,
    BackendStringToDate
  ]
})
export class DateModule {
}
