export class WellnessDiffs {
  fatigue: number;
  lowerBodyMuscleSoreness: number;
  upperBodyMuscleSoreness: number;
  stressLevel: number;
  mood: number;
  sleepQuality: number;
  asleepDiffInSecond: number;
  howManyHoursDidYouSlept: number;
  fatigueDifFromPrev: number;
  lowerBodyMuscleSorenessDifFromPrev: number;
  upperBodyMuscleSorenessDifFromPrev: number;
  stressLevelDifFromPrev: number;
  moodDifFromPrev: number;
  sleepQualityDifFromPrev: number;
  asleepDiffInSecondDifFromPrev: number;
  howManyHoursDidYouSleptDifFromPrev: number;
  fatigueDifFromTeam: number;
  lowerBodyMuscleSorenessDifFromTeam: number;
  upperBodyMuscleSorenessDifFromTeam: number;
  stressLevelDifFromTeam: number;
  moodDifFromTeam: number;
  sleepQualityDifFromTeam: number;
  asleepDiffInSecondDifFromTeam: number;
  howManyHoursDidYouSleptDifFromTeam: number;
  allValueTeamAVG: number;

  constructor() {
    this.fatigue = 0;
    this.lowerBodyMuscleSoreness = 0;
    this.upperBodyMuscleSoreness = 0;
    this.stressLevel = 0;
    this.mood = 0;
    this.sleepQuality = 0;
    this.asleepDiffInSecond = 0;
    this.howManyHoursDidYouSlept = 0;
    this.fatigueDifFromPrev = 0;
    this.lowerBodyMuscleSorenessDifFromPrev = 0;
    this.upperBodyMuscleSorenessDifFromPrev = 0;
    this.stressLevelDifFromPrev = 0;
    this.moodDifFromPrev = 0;
    this.sleepQualityDifFromPrev = 0;
    this.asleepDiffInSecondDifFromPrev = 0;
    this.howManyHoursDidYouSleptDifFromPrev = 0;
    this.fatigueDifFromTeam = 0;
    this.lowerBodyMuscleSorenessDifFromTeam = 0;
    this.upperBodyMuscleSorenessDifFromTeam = 0;
    this.stressLevelDifFromTeam = 0;
    this.moodDifFromTeam = 0;
    this.sleepQualityDifFromTeam = 0;
    this.asleepDiffInSecondDifFromTeam = 0;
    this.howManyHoursDidYouSleptDifFromTeam = 0;
    this.allValueTeamAVG = 0;
  }

  Rand() {
    this.fatigue = Math.floor(Math.random() * 4) + 1;
    this.lowerBodyMuscleSoreness = Math.floor(Math.random() * 4) + 1;
    this.upperBodyMuscleSoreness = Math.floor(Math.random() * 4) + 1;
    this.stressLevel = Math.floor(Math.random() * 4) + 1;
    this.mood = Math.floor(Math.random() * 4) + 1;
    this.sleepQuality = Math.floor(Math.random() * 4) + 1;
    this.asleepDiffInSecond = Math.floor(Math.random() * 4) + 1;
    this.howManyHoursDidYouSlept = Math.floor(Math.random() * 4) + 1;
    this.fatigueDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.lowerBodyMuscleSorenessDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.upperBodyMuscleSorenessDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.stressLevelDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.moodDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.sleepQualityDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.asleepDiffInSecondDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.howManyHoursDidYouSleptDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.fatigueDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.lowerBodyMuscleSorenessDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.upperBodyMuscleSorenessDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.stressLevelDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.moodDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.sleepQualityDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.asleepDiffInSecondDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.howManyHoursDidYouSleptDifFromTeam = Math.floor(Math.random() * 4) + 1;
    this.allValueTeamAVG = Math.floor(Math.random() * 4) + 1;
  }
}
