import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sgs-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
