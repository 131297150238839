import { Injectable } from '@angular/core';
import { FirstDayOfMonth, FirstDayOfWeek, LastDayOfMonth, LastDayOfWeek, DiffBetweenDays } from './date.pipe';

@Injectable()
export class DateTransferService {

  private _date: { begin: Date, end: Date };
  private _singleDate: Date;
  private _ChangeCallback: Function;
  private _grouping = '0';
  private _divide = { weekly: false, monthly: false };

  constructor() {
    this.setDefaultDay();
    this.setDefaultWeek();
  }

  get grouping(): string {
    return this._grouping;
  }

  set grouping(value: string) {
    this._grouping = value;
  }

  setDefaultDay() {
    this._singleDate = new Date(Date.now());
  }
  setDefaultWeek() {
    this._date = { begin: (new FirstDayOfWeek).transform(new Date()), end: (new LastDayOfWeek).transform(new Date()) };
  }

  setDeafultMonth() { }

  changed() {
    switch (this._grouping) {
      case '0': {
        this._ChangeCallback();
        this.enableDivideSelection();
        break;
      }
      case '1': {
        this._date.begin = new FirstDayOfWeek().transform(this._date.begin);
        this._date.end = new LastDayOfWeek().transform(this._date.end);
        this._ChangeCallback();
        this.enableDivideSelection();
        break;
      }
      case '7': {
        this._date.begin = new FirstDayOfWeek().transform(this._date.begin);
        this._date.end = new LastDayOfWeek().transform(this._date.end);
        this._ChangeCallback();
        this.enableDivideSelection();
        break;
      }
      case '30': {
        this._date.begin = new FirstDayOfMonth().transform(this._date.begin);
        this._date.end = new LastDayOfMonth().transform(this._date.end);
        /*Itt az lesz a para, hogy ha beállítunk eredendően egy dátumot mondjuk július 1 vasárnaptól
        augusztus 31 péntekig, akkor automatikusan jun. 25- szept.2 lesz a beállított intervallum,
        és ezt hónapra konvertálva már nem 2, hanem 5 hónap lesz az intervallum és a bontás is 5 hónapot
        mutat majd (jun.1-szept-30.) pedig ilyen esetben szerintem(!) nem ez a cél. */
        this._ChangeCallback();
        this.enableDivideSelection();
        break;
      }
    }
  }

  set ChangeCallback(value: Function) {
    this._ChangeCallback = value;
  }

  get date(): { begin: Date; end: Date } {
    return this._date;
  }
  set date(value: { begin: Date; end: Date }) {
    this._date = value;
  }

  get singleDate(): Date {
    return this._singleDate;
  }
  set singleDate(value: Date) {
    this._singleDate = value;
  }

  enableDivideSelection() {
    const term = new DiffBetweenDays().transform(this._date.begin, this.date.end);
    if (term < 27) {
      this._divide.weekly = true;
    } else if (term >= 27) { this._divide.weekly = false; }
    if (term < 55) {
      this._divide.monthly = true;
    } else if (term >= 55) { this._divide.monthly = false; }
  }

  get divide() {
    return this._divide;
  }
  set divide(value) {
    this._divide = value;
  }

  groupingEnable() {
    this.grouping = '1';
  }
}
