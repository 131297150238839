import {Injectable} from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './core/auth/auth.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/open/login']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/open/login']);
      return false;
    }
    return true;
  }

}

@Injectable()
export class MainGuard implements CanActivate, CanActivateChild {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated() || (!this.auth.isAdmin() && !this.auth.isTrainer())) {
      this.router.navigate(['/open/login']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isAuthenticated() || (!this.auth.isAdmin() && !this.auth.isTrainer())) {
      this.router.navigate(['/open/login']);
      return false;
    }
    return true;
  }

}

@Injectable()
export class WellAdminGuard implements CanActivate, CanActivateChild {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated() || (!this.auth.isAdmin() && !this.auth.isTrainer())) {
      this.router.navigate(['/well/wlogin']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isAuthenticated() || (!this.auth.isAdmin() && !this.auth.isTrainer())) {
      this.router.navigate(['/well/wlogin']);
      return false;
    }
    return true;
  }

}

@Injectable()
export class WellGuard implements CanActivate, CanActivateChild {

  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/well/wlogin']);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['/open/login']);
      return false;
    }
    return true;
  }
}
