import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'PlayerFilterPipe',
})
export class PlayerFilterPipe implements PipeTransform {
  transform(value: any, input: string) {
    if (input !== undefined) {
      return value.filter(function (el: any) {
        return el.name.toString().toLowerCase().indexOf(input.toLowerCase()) > -1;
      });
    }
    return value;
  }
}

@Pipe({
  name: 'MatchFilterPipe',
})
export class MatchFilterPipe implements PipeTransform {
  transform(value: any, input: string) {
    if (input) {
      return value.filter(function (el: any) {
        if (el.team1Id === 5) {
          return el.team2.name.toString().toLowerCase().indexOf(input.toLowerCase()) > -1;
        } else { return el.team1.name.toString().toLowerCase().indexOf(input.toLowerCase()) > -1; }
      });
    }
    return value;
  }
}

@Pipe({
  name: 'MatchResultPipe',
})
export class MatchResultPipe implements PipeTransform {
  transform(value: any, input: string) {
    if (input === 'all') {
      return value;
    } else {
      return value.filter(el => el.result === input);
    }
  }
}

@Pipe({
  name: 'post',
})
export class PostPipe implements PipeTransform {
  transform(value: any) {
    switch (value.toLowerCase()) {
      case 'wing': return 'POSTS.WING';
      case 'full back': return 'POSTS.FULL_BACK';
      case 'attacker': return 'POSTS.ATTACKER';
      case 'midfielder': return 'POSTS.MIDFIELDER';
      case 'goalkeeper': return 'POSTS.GOALKEEPER';
      case 'defender': return 'POSTS.DEFENDER';
      case 'centre back': return 'POSTS.CENTRE_BACK';
      case 'central midfielder': return 'POSTS.MIDFIELDER';
      default: return 'POSTS.NA';
    }
  }
}
