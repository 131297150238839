export interface Player {
  id: number;
  name: string;
  number: number;
  post: string;
  pic: string;
  matchingName: string;
  mobile: string;
  email: string;
  birthDate: Date;
}

export interface Team {
  id: number;
  name: string;
  pic: string;
}

export class PlayerListModel {

  private _team: Team[];
  private _players: Player[];

  constructor() {
    this._players = [];
  }

  set team(team: Team[]) {
    this._team = team;
  }
  addteam(team: Team) {
    this._team.push(team);
  }
  set players(value: Player[]) {
    this._players = value;
  }

  addplayer(player: Player) {
    this._players.push(player);
  }

  get team(): Team[] {
    return this._team;
  }

  get players(): Player[] {
    return this._players;
  }

  clear() {
    this._players = [];
  }
}
