import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Match } from '../matchList.service';
import {DataShareService} from '../../../core/DataShare/DataShare.service';

@Component({
  selector: 'sgs-match-card',
  templateUrl: './matchCard.component.html'
})
export class MatchCardComponent implements OnInit {
  @Input() match: Match;
  @Input() active: number;
  @Output() update: EventEmitter<number> = new EventEmitter();

  private matches: Array<Match>;

  new_choose(id: number) {
  }

  constructor(public DataShare: DataShareService) {
  }

  ngOnInit() {
  }

}
