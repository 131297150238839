export function backendDateStringToDate(dateString: string): Date {
  const date = new Date();
  const parts = dateString.split('-');
  date.setFullYear(
    Number(parts[0].replace(/^0+/, '')),
    Number(parts[1].replace(/^0+/, '')),
    Number(parts[2].replace(/^0+/, ''))
  );
  date.setHours(0, 0, 0, 0);
  return date;
}
