export class LoginException implements Error {
  readonly name: string;
  readonly message: string;
  readonly error: any | null;
  errorCode: number;
  LoginException = true;

  constructor(errorCode: number) {
    this.name = 'LoginException';
    this.message = 'Login Error';
    this.error = 401;
    this.errorCode = errorCode;
  }
}
