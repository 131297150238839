import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material';
import { BaseChartDirective } from './base-chart/base-chart.directive';
import { ExternalUrlDirective } from './external-url/external-url.directive';
import { DateModule } from '../core/date/date.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import {
  MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule,
  MatSelectModule, MatOptionModule, MatIconModule, MatDialogModule
} from '@angular/material';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { PlayerCardComponent } from '../main/playerList/playerCard';
import { TeamCardComponent } from '../main/playerList/teamCard';
import { MatchCardComponent } from '../main/playerList/matchCard';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { PostPipe } from '../main/playerList/filter.pipe';
import {NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

registerLocaleData(localeHu);
/**
 * Components
 */
const sharedComponents: any[] = [
  PlayerCardComponent,
  TeamCardComponent,
  MatchCardComponent
];

/**
 * Services
 */
const sharedServices: any[] = [
];

/**
 * Directives
 */
const sharedDirectives: any[] = [
  BaseChartDirective,
  ExternalUrlDirective
];

@NgModule({
  declarations: [
    sharedComponents,
    sharedDirectives,
    PostPipe,
    ConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    DateModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    FormsModule,
    NgbTimepickerModule,
    NgbCollapseModule,
    MatDialogModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    sharedComponents,
    sharedDirectives,
    DateModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    PostPipe,
    NgbTimepickerModule,
    NgbCollapseModule,
    MatDialogModule
  ],
  providers: [
    PostPipe,
    sharedServices,
    /*{ provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },*/
    { provide: LOCALE_ID, useValue: 'hu-HU' }

  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class SharedModule { }
