export class WellnessDiffsPrev {
  fatigue: number;

  lowerBodyMuscleSoreness: number;

  upperBodyMuscleSoreness: number;

  stressLevel: number;

  mood: number;

  sleepQuality: number;

  asleepDiffInSecond: number;

  howManyHoursDidYouSlept: number;

  fatigueDifFromPrev: number;

  lowerBodyMuscleSorenessDifFromPrev: number;

  upperBodyMuscleSorenessDifFromPrev: number;

  stressLevelDifFromPrev: number;

  moodDifFromPrev: number;

  sleepQualityDifFromPrev: number;

  asleepDiffInSecondDifFromPrev: number;

  howManyHoursDidYouSleptDifFromPrev: number;

  constructor() {
    this.fatigue = 0;
    this.lowerBodyMuscleSoreness = 0;
    this.upperBodyMuscleSoreness = 0;
    this.stressLevel = 0;
    this.mood = 0;
    this.sleepQuality = 0;
    this.asleepDiffInSecond = 0;
    this.howManyHoursDidYouSlept = 0;
    this.fatigueDifFromPrev = 0;
    this.lowerBodyMuscleSorenessDifFromPrev = 0;
    this.upperBodyMuscleSorenessDifFromPrev = 0;
    this.stressLevelDifFromPrev = 0;
    this.moodDifFromPrev = 0;
    this.sleepQualityDifFromPrev = 0;
    this.asleepDiffInSecondDifFromPrev = 0;
    this.howManyHoursDidYouSleptDifFromPrev = 0;
  }

  Rand() {
    this.fatigue = Math.floor(Math.random() * 4) + 1;
    this.lowerBodyMuscleSoreness = Math.floor(Math.random() * 4) + 1;
    this.upperBodyMuscleSoreness = Math.floor(Math.random() * 4) + 1;
    this.stressLevel = Math.floor(Math.random() * 4) + 1;
    this.mood = Math.floor(Math.random() * 4) + 1;
    this.sleepQuality = Math.floor(Math.random() * 4) + 1;
    this.asleepDiffInSecond = Math.floor(Math.random() * 4) + 1;
    this.howManyHoursDidYouSlept = Math.floor(Math.random() * 4) + 1;
    this.fatigueDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.lowerBodyMuscleSorenessDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.upperBodyMuscleSorenessDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.stressLevelDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.moodDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.sleepQualityDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.asleepDiffInSecondDifFromPrev = Math.floor(Math.random() * 4) + 1;
    this.howManyHoursDidYouSleptDifFromPrev = Math.floor(Math.random() * 4) + 1;
  }
}
