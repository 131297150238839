import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './core/not-found/not-found.component';
import { externalUrlProvider } from './core';
import {MainGuard} from './app.guard';

const routes: Routes = [
  { path: '', redirectTo: 'open', pathMatch: 'full' },
  {path: 'main', canActivate: [MainGuard], canActivateChild: [MainGuard], loadChildren: './main/main.module#MainModule'},
  { path: 'open', loadChildren: './open/open.module#OpenModule' },
  { path: 'well', loadChildren: './wellness-send/wellness-send.module#WellnessSendModule' },
  {path: 'mobil', loadChildren: './wellness-send/wellness-send.module#WellnessSendModule'},
  { path: '**', component: NotFoundComponent },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
