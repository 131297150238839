import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from 'src/app/shared';
import { CoreModule } from 'src/app/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { HttpService } from './core/http/http.service';
import { AuthGuard, MainGuard, WellGuard } from './app.guard';
import { AuthService } from './core/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { HuDateAdapter } from 'src/app/core/date/date-adapter';
import { DateAdapter } from 'saturn-datepicker';
import { DateTransferService } from './core/date/dateTransfer.service';
import { PlayerListService } from './main/playerList/playerList.service';
import { DataShareService } from './core/DataShare/DataShare.service';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { GlobalErrorHandler } from './core/error/GlobalErrorHandler';

const appComponents: any[] = [
  AppComponent,
];

@NgModule({
  declarations: [
    appComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule.forRoot(),
    ReactiveFormsModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    DragulaModule.forRoot(),
    SimpleNotificationsModule.forRoot({
      timeOut: 3000,
      preventDuplicates: false,
      showProgressBar: false,
      pauseOnHover: true,
      clickToClose: true,
      clickIconToClose: true
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: DateAdapter, useClass: HuDateAdapter },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    HttpService,
    AuthGuard,
    MainGuard,
    WellGuard,
    AuthService,
    CookieService,
    DateTransferService,
    PlayerListService,
    DataShareService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
