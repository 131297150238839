import { NativeDateAdapter } from '@angular/material';
import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable()
export class HuDateAdapter extends NativeDateAdapter {

    /*constructor() {
        super('hu_HU', new Platform());
    }*/
    getFirstDayOfWeek(): number {
        return 1;
    }

}
